import styled from 'styled-components';

import Layout from 'layouts';
import { Head } from 'humanity/components/head';
import Button from 'legacy/components/button';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import Box from 'legacy/components/box';
import Flex from 'legacy/components/flex';
import Section from 'legacy/components/section';
import { getLayoutById } from 'lib/contentful/getLayoutById';
import rollbar from 'utils/rollbar';
import { contentfulLayoutType } from 'types';

const MultColumnsContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 4rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 42rem;
  }
`;

const Custom404 = ({ layout }) => (
  <Layout layout={layout}>
    <Head title="404" noIndex />
    <Section minHeight="50vh">
      <Section.Inner>
        <Box maxWidth={['auto', 'auto', '47%']}>
          <Heading as="h1" fontSize={[5, 5, 6]} mt={0}>
            This page is not available
          </Heading>
          <Text mt={4}>Make a selection below to get back on the right path.</Text>
        </Box>
        <MultColumnsContainer>
          <Button
            as="a"
            display="block"
            href="/"
            variant="primary"
            width={['auto', '30%', '30%', 210]}
            mr={[0, 2]}
          >
            Go to homepage
          </Button>
          <Button
            mt={[2, 0]}
            mr={[0, 2]}
            as="a"
            display="block"
            href="/learn/"
            width={['auto', '30%', '30%', 210]}
          >
            Learning Center
          </Button>
          <Button
            mt={[2, 0]}
            as="a"
            display="block"
            href="https://support.humaninterest.com/"
            width={['auto', '30%', '30%', 210]}
          >
            Check our Support
          </Button>
        </MultColumnsContainer>
      </Section.Inner>
    </Section>
  </Layout>
);

Custom404.propTypes = {
  layout: contentfulLayoutType.isRequired,
};

export const getStaticProps = async ({ preview = false }) => {
  const LAYOUT_ID = '5Dp0YOtO45t9vruD0JwAWO';

  try {
    const layout = await getLayoutById(LAYOUT_ID, preview);

    return { props: { layout } };
  } catch (err) {
    rollbar.error('Error fetching layout for 404 page', err, err.response?.data);
    // return the page with empty props to get the fallback layout
    return { props: {} };
  }
};

export default Custom404;
